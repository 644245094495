<template>
<v-app id="inspire">
    <div class="px-4">
        <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
        <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" />
        <v-container class="container--fluid grid-list-md text-center">
            <h1>Merchant Service Fee</h1> 
        </v-container>
        <v-card elevation="2" outlined shaped tile class="pa-1"  v-if="hasPermission('ROLE_PAYCONNECT_VIEW_ADJUSTMENTS')">
            <v-row class="ma-1">
                <v-layout row wrap>
                    <v-flex xs12 sm6>
                        <v-autocomplete clearable chips :items="merchantList" item-text="name" item-value="merchantId" label="Merchant" v-model="query.merchant" return-object @change="search($event, 'merchant')" data-cy="module-merchant">
                        </v-autocomplete>
                    </v-flex>
                    <v-flex xs12 sm6>
                    </v-flex>    
                    <v-flex xs12 sm6>
                        <v-text-field label="Product Name" v-model="query.productName" single-line outlined @change="search($event, 'productName')" data-cy="module-product-name"></v-text-field>
                    </v-flex>
                </v-layout>
            </v-row>
            <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title>
                    <v-container grid-list-md text-xs-center>
                        <v-layout wrap>
                            <v-flex xs12 sm6>
                                <v-flex xs12 sm6 v-if="hasPermission('ROLE_PAYCONNECT_VIEW_ADJUSTMENTS')">
                                    <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color=success @click="queryData(true)" data-cy="button-search">
                                        <v-icon dark left> mdi-magnify</v-icon> Search
                                    </v-btn>
                                </v-flex>
                            </v-flex>
                            <v-flex xs12 sm6>
                                <v-flex xs12 sm6 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_ADJUSTMENTS')">
                                    <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color="success" @click.stop="showAdjustmentForm('CREATED')">New Service Fee</v-btn>
                                    <adjustment-form ref="AdjustmentForm" :selected-item="selectedAdjustment" :merchants="merchants" :is-show="showFormDialog" :form-type="adjustmentFormType" v-on:close-dialog="closeDialog" v-on:save-adjustment="saveAdjustment" />
                                </v-flex>
                            </v-flex>
                            <v-flex xs12 sm6>
                                <v-flex xs12 sm6 v-if="hasPermission('ROLE_PAYCONNECT_VIEW_ADJUSTMENTS')">
                                    <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color=success @click="download()">
                                        <v-icon dark left> mdi-cloud-download</v-icon> Download CSV
                                    </v-btn>
                                </v-flex>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-title>
            </div>
        </v-card>

        <v-data-table :hide-default-header="true" :headers="headers" :items="displayAdjust" :loading="loading" :page="page" :pageCount="numberOfPages" :options.sync="options" :server-items-length="totalAdjust" :footer-props="{'items-per-page-options': [10]}" class="elevation-2 mt-4 px-4">
            <template v-slot:header="{ props: { headers } }">
                <thead>
                    <tr :colspan="headers.length">
                        <th v-for="header in headers" :key="header.text">
                            <span class="d-flex justify-center">{{header.text}}</span>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                        <td> <span class="d-flex justify-center"> {{ item.merchant.name }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.productName }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.productCode }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.percentAdjustment }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.fixedAdjustment }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.minimumAmount }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.maximumAmount }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.applicationDate }} </span></td>
                        <td>
                            <span class="d-flex justify-center">
                                <v-btn fab dark x-small color="green" class="mr-2" @click.stop="viewAdjustments(item)">
                                    <v-icon dark medium>mdi-magnify-plus</v-icon>
                                </v-btn>
                                <v-btn fab dark x-small color="green" @click.stop="showAdjustmentForm('UPDATE', item)" v-if="hasPermission('ROLE_PAYCONNECT_UPDATE_ADJUSTMENTS')">
                                    <v-icon dark medium>mdi-pencil</v-icon>
                                </v-btn>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">
                    Found no results.
                </v-alert>
            </template>
        </v-data-table>
    </div>
</v-app>
</template>

<script>
import API from '@/api/API.js'
import moment from 'moment';
import Loading from '@/views/components/Loading.vue'
import DetailsForm from '@/views/components/DetailsForm.vue'
import AdjustmentForm from '@/views/components/AdjustmentForm.vue'
import SwitchAPI from '@/api/SwitchAPI'
import {
    mapGetters
} from 'vuex';

export default {
    name: 'Adjustments',
     components: {
        Loading,
        DetailsForm,
        AdjustmentForm
    },
    data() {
        return {
            headers: [],
            details: {
                title: '',
                item: {}
            },
            merchants: [],
            merchantList: this.merchants ?? [],
            selectedMerchant: '',
            selectedAdjustment: {},
            isShowDetails: false,
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            page: 1,
            numberOfPages: 0,
            options: {
                rowsPerPage: 10
            },
            query: {
                merchant: '', 
                productName: ''
            },
            adjustmentFormType: 'CREATE',
            showFormDialog: false,
            totalAdjust: 0,
            displayAdjust: [],
            remainderAdjust: [],
            filterQry: {
                qryMerchant: "",
            },
            isHtml: false
        }
    },
    watch: {
        options: {
            handler() {
                console.log('1')
                this.queryData()
                this.setData()
            },
        },
        merchants: {
            handler(value) {
                this.merchantList = value
            }
        },
        deep: true,
    },
    computed: {
        //get role attributes
        ...mapGetters([
            'attributes',
            'user',
            'authorities'
        ])
    },
    async mounted() {
        this.setHeaders()
        //this.queryData()
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.options.page = 1
            }
            const {
                page,
                itemsPerPage
            } = this.options;
            let pageNumber = page - 1;
            try {
                const limit = itemsPerPage
                const listResponse = await SwitchAPI.getAdjustments(pageNumber, limit, this.query)
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`)
                } else {
                    var filteredList = listResponse.adjustments
                    if (page > 1 && this.remainderAdjust.length > 0) {
                        filteredList = this.remainderAdjust.concat(filteredList)
                    }
                    this.totalAdjust = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                    this.displayAdjust = filteredList.slice(0, end)
                    this.remainderAdjust = filteredList.length 	< itemsPerPage ? [] : filteredList.slice(end)
                    this.numberOfPages = this.totalAdjust / itemsPerPage
                    const hasMoreResponse = await SwitchAPI.getAdjustments(this.totalAdjust, 1, this.query)
                    if (!hasMoreResponse || hasMoreResponse.error) {
                        //error getting data
                        console.log(`${hasMoreResponse.error}`)
                    } else {
                        var hasMoreList = hasMoreResponse.adjustments
                        if(hasMoreList.length > 0) {
                          this.totalAdjust = this.totalAdjust + 1
                          this.numberOfPages = this.numberOfPages + 1
                        }
                    }
                }
                this.loading = false
            } catch (e) {
                console.log(e)
                this.loading = false
            }
	    },
        
        //method to update the Merchant query
        searchMerchant: async function (val) {
            this.selectedMerchant = val;
            this.queryData(true)
        },
        getMerchantId() {
            const attributes = this.attributes ? JSON.parse(this.attributes) : {}
            const merchants = attributes ? attributes.merchant ?? [] : []
            const merchantString = merchants ? merchants.length > 0 ? merchants[0] : '' : ''
            if (merchantString !== '') {
                const merchant = JSON.parse(merchantString)
                const merchantId = merchant.merchantId
                return merchantId
            }
            return merchantString
        },
        async download() {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            this.loadingMessage = `Downloading...`
            const downloadResponse = await SwitchAPI.downloadCsv()
            this.loadingFinished = true
            if (!downloadResponse || downloadResponse.error) {
                this.isHtml = false
                this.loadingMessage = downloadResponse ? downloadResponse.error : "Error downloading file."
            } else {
                this.loadingMessage = `Download complete.`
            }
        },
        showAdjustmentForm(what, item = {}) {
            this.adjustmentFormType = what
            this.selectedAdjustment = item
            this.showFormDialog = true
        },
        setHeaders() {
            var headers = [
                {
                    text: 'Merchant',
                    value: 'merchant.name'
                },
                {
                    text: 'Product Name',
                    value: 'productName'
                },
                {
                    text: 'Item Code',
                    value: 'productCode'
                },
                {
                    text: 'Percent Service Fee',
                    value: 'percentAdjustment'
                },
                {
                    text: 'Fixed Service Fee',
                    value: 'fixedAdjustment'
                },
                {
                    text: 'Minimum Amount',
                    value: 'minimumAmount'
                },
                {
                    text: 'Maximum Amount',
                    value: 'maximumAmount'
                },
                {
                    text: 'Application Date',
                    value: 'applicationDate'
                }
            ]
            this.headers = headers
        },
        viewAdjustments(adjustment) {
            this.details.title = adjustment.id
            this.details.item = adjustment
            this.isShowDetails = true
        },
        closeDialog(type) {
            console.log('type:', type);
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                this.queryData();
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        search(event, type) {
            this.query[type] = event
        },
        async setData() {
            try {
                const listResponse = await API.getMerchants()
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`)
                } else {
                    this.merchants = listResponse.merchants
                }
            } catch (e) {
                console.log(e)
            }
        },
        async saveAdjustment(value) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
              console.log(value)
                if (value.formType === 'CREATED') {
                    this.loadingMessage = `Adding new service fee for <strong><i>${value.merchant.name}</i></strong>`
                    setTimeout(async () => {
                        const createResponse = await SwitchAPI.createAdjustment(value)
                        console.log(createResponse)
                        this.loadingFinished = true
                        if (!createResponse || createResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = createResponse.error
                        } else {
                            this.loadingMessage = `Successfully added service fee for <strong><i>${value.merchant.name}</i></strong>`
                            this.showFormDialog = false
                            this.$refs.AdjustmentForm.resetForm()
                        }
                    }, 2000);

                } else if (value.formType === 'UPDATE') {
                    this.loadingMessage = `Updating service fee <strong><i>${value.id}</i></strong>`
                        console.log("date " + value.applicationDate)
                        console.log("merchant " + value.merchant)
                    setTimeout(async () => {
                        const updateResponse = await SwitchAPI.updateAdjustment(value)
                        this.loadingFinished = true
                        if (!updateResponse || updateResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = updateResponse.error
                        } else {
                            this.loadingMessage = `Successfully updated adjustment <strong><i>${value.id}</i></strong>`
                            this.showFormDialog = false
                            this.$refs.AdjustmentForm.resetForm()
                        }
                    }, 2000);
                } else {
                    this.loadingMessage = "Invalid command"
                    this.loadingFinished = true
                }
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
    }
};
</script>

<style scoped>
.v-data-table {
    max-width: 100%
}
</style>
