<template>
<v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card ref="formCard">
            <v-card-title>
                <span class="headline" v-if="formType === 'UPDATE'">Update Merchant Service Fee</span>
                <span class="headline" v-else>New Merchant Service Fee</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formEntry">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-autocomplete clearable chips :items="merchantList" item-text="name" item-value="merchantId" label="Merchant*" v-model="form.merchant" required :rules="[rules.requiredField]" data-cy="form-merchant" return-object>
                                </v-autocomplete>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Product Name" v-model="form.productName" data-cy="form-product-name" > </v-text-field>
                            </v-flex>    
                            <v-flex xs12>
                                <v-text-field label="Item Code*" required :rules="[rules.requiredField]" v-model="form.productCode" data-cy="form-product-code" > </v-text-field>
                            </v-flex>    
                            <v-flex xs12>
                                 <v-menu v-model="showDateAppliedMenu" required :rules="[rules.requiredField]" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field label="Date of Application*" prepend-icon="mdi-calendar" readonly :value="displayDateApplied" v-on="on" required :rules="[rules.requiredField]"></v-text-field>
                                    </template>
                                    <v-date-picker locale="en-in" v-model="form.applicationDate" data-cy="form-application-date" no-title @input="showDateAppliedMenu = false" ></v-date-picker>
                                </v-menu>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Percent Service Fee" v-model="form.percentAdjustment" data-cy="form-percent-adjustment" @keypress="isNumber($event)" > </v-text-field>
                            </v-flex>    
                            <v-flex xs12>
                                <v-text-field label="Fixed Service Fee" v-model="form.fixedAdjustment" data-cy="form-fixed-adjustment" @keypress="isNumber($event)" > </v-text-field>
                            </v-flex>    
                            <v-flex xs12>
                                <v-text-field label="Minimum Amount" v-model="form.minimumAmount" data-cy="form-minimum-amount" @keypress="isNumber($event)" > </v-text-field>
                            </v-flex>    
                            <v-flex xs12>
                                <v-text-field label="Maximum Amount" v-model="form.maximumAmount" data-cy="form-maximum-amount" @keypress="isNumber($event)" > </v-text-field>
                            </v-flex>    
                        </v-layout>
                    </v-container>
                </v-form>
                <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text v-on:click="close" data-cy="close-button">Close</v-btn>
                <v-btn color="blue darken-1" text v-on:click="save" data-cy="save-button">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-layout>
</template>

<script>
export default {
    props: {
        isShow: Boolean,
        formType: String,
        loadingDialog: Boolean,
        selectedItem: Object,
        merchants: Array,
    },
    computed: {
        populatedForm() {
            return {
                id: this.form.id,
                merchant: this.form.merchant,
                productName: this.form.productName,
                productCode: this.form.productCode,
                applicationDate: this.form.applicationDate,
                percentAdjustment: this.form.percentAdjustment,
                fixedAdjustment: this.form.fixedAdjustment,
                minimumAmount: this.form.minimumAmount,
                maximumAmount: this.form.maximumAmount,                
                formType: this.formType
            }
        },
        displayDateApplied() {
            return this.form.applicationDate
        }
    },
    watch: {
        isShow: {
            handler(value) {
                this.dialog = value
            }
        },
        loadingDialog: {
            handler(value) {
                this.loading = value
            },
        },
        dialog: {
            handler(value) {
                if (value === true) {
                    this.initializeForm()
                }
            }
        },
        merchants: {
            handler(value) {
                this.merchantList = value
            }
        },
        deep: true,
    },
    data() {
        return {
            dialog: this.isShow ?? false,
            loading: this.loadingDialog ?? false,
            merchantList: this.merchants ?? [],
            showDateAppliedMenu: false,
            form: {
              merchant: {}
            },
            rules: {
                requiredField: v => !!v || "Required field.",
                requiredSelection: v => !!v && v.length > 0 || "Required at least one selection"
            }
        }
    },
    mounted() {
        this.initializeForm()
    },
    methods: {
        close() {
            this.dialog = false
            this.$refs.formEntry.reset()
            this.$refs.formCard.$el.scrollIntoView({
                behavior: 'smooth'
            })
            this.$emit('close-dialog', this.formType)
        },
        save() {
            if (this.$refs.formEntry.validate()) {
                this.$emit('save-adjustment', this.populatedForm)
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        },
        resetForm() {
            this.$refs.formEntry.reset()
        },
        initializeForm() {
            if (this.formType === 'CREATED') {
                this.form = {
                    id: undefined,
                    merchant: undefined,
                    productName: undefined,
                    productCode: undefined,
                    applicationDate: undefined,
                    percentAdjustment: undefined,
                    fixedAdjustment: undefined,
                    minimumAmount: undefined,
                    maximumAmount: undefined
                }
            } else if (this.formType === 'UPDATE' && this.selectedItem) {
                this.form = {
                    id: this.selectedItem.id,
                    merchant: this.selectedItem.merchant ?? {},
                    productName: this.selectedItem.productName,
                    productCode: this.selectedItem.productCode,
                    applicationDate: this.selectedItem.applicationDate,
                    percentAdjustment: this.selectedItem.percentAdjustment,
                    fixedAdjustment: this.selectedItem.fixedAdjustment,
                    minimumAmount: this.selectedItem.minimumAmount,
                    maximumAmount: this.selectedItem.maximumAmount
                }
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;

            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46 && charCode !== 45) {
                evt.preventDefault();
            } else {
                return true;
            }
        }
    }
}
</script>
